import React from 'react';
import PropTypes from 'prop-types'
import { Controller } from 'react-scrollmagic';
import { graphql } from "gatsby"
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout'
import Heading from '../../components/shared/heading'
import Text from '../../components/shared/text'
import SectionTitle from '../../components/shared/section-title'
import Image from '../../components/image'
import Project from '../../components/project'
import {
    ProjectDetailsWrap, 
    ProjectHeaderWrap, 
    ProjectHeaderMeta,
    ProjectType,
    ProjectFeatureWrap,
    FullwidthBox,
    NextProjectWrap,
    AboutSectionWrap,
    ProjectTypeList
} from './project-template.stc'
import {
    BlogDetailsHeader,
} from '../blog-template/blog-template.stc'

const ProjectTemplate = ({data, pageContext: {next, previous}, ...restProps}) => {
    const { title, metaHeading, metaText, nextProjectStyle, metaTextStyle, titleStyle, ProjectTypeHeading, ProjectTypeText } = restProps;
    const projectData = data.projectsJson;
    const { body, features, cover_image, featured_work_project } = projectData;
    const delay = [200];
    // console.log(projectData)
    return (
        <Layout>
            <SEO title={projectData.titleSEO} description={projectData.descSEO}/>
            <BlogDetailsHeader fluid={cover_image.childImageSharp.fluid}>
                <div className="row align-items-center">
                    <div className="col-3 offset-1">
                        <div className="rn-blog-details-meta-inner">
                            <Text {...metaTextStyle}>КЕЙС</Text>
                            <Heading {...titleStyle}>{projectData.title}</Heading>
                            <Text {...metaTextStyle}>{projectData.client}</Text>
                        </div>
                    </div>
                </div>
            </BlogDetailsHeader>
            <ProjectDetailsWrap>
                <ProjectHeaderWrap className="section-ptb-xl">
                    <div className="row">
                        <div className="col-1 offset-1">
                            <div className="rn-project-meta-inner">
                                {projectData.title && <Heading {...title}>{projectData.subTitle}</Heading>}
                                <ProjectHeaderMeta>
                                    {projectData.site && (
                                        <ProjectType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                            <Heading {...metaHeading}>Клинет</Heading>
                                            <Text {...metaText}>{projectData.site}</Text>
                                        </ProjectType>
                                    )}
                                    {projectData.services && (
                                        <ProjectType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                            <Heading {...metaHeading}>Услуги</Heading>
                                            {projectData.services.map((item,index)=><Text key={index} {...metaText}>{item}</Text>)}
                                            
                                        </ProjectType>
                                    )}
                                    {projectData.year && (
                                        <ProjectType className="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                            <Heading {...metaHeading}>Год</Heading>
                                            <Text {...metaText}>{projectData.year}</Text>
                                        </ProjectType>
                                    )}
                                </ProjectHeaderMeta>
                            </div>
                        </div>
                        <div className="col-2 offset-1">
                            <div className="rn-project-content">
                                {body && body.map((text, i) => <p key={`text-${i}`}><div dangerouslySetInnerHTML={{__html: text}}/></p>)}
                            </div>
                        </div>
                    </div>
                </ProjectHeaderWrap>
                {features && features.map(feature => (
                    <div key={feature.id} className="project-features">
                        <FullwidthBox>
                            <Controller>
                                {/* <Scene classToggle="animated" triggerElement={`#${feature.id}`} triggerHook="onCenter"> */}
                                    <div className="rn_surface" id={feature.id}>
                                        <Image fluid={feature.image.childImageSharp.fluid} alt="single"/>
                                    </div>
                                {/* </Scene> */}
                            </Controller>
                        </FullwidthBox>
                        <ProjectFeatureWrap className="section-ptb-xl">
                            <div className="row">
                                <div className="col-1 offset-1">
                                    <div className="rn-project-meta-inner">
                                        <div className="section-title">
                                            <Heading {...title}>{feature.title}</Heading>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 offset-1">
                                    <div className="rn-project-content">
                                        {feature.text.map((ft, i) => <p key={`ft-${i}`}>{ft}</p>)}
                                    </div>
                                </div>
                            </div>
                        </ProjectFeatureWrap>
                    </div>
                ))}
                {featured_work_project && (
                    <AboutSectionWrap>
                        <div className="row section-pt-md">
                            <div className="col-4 offset-1">
                                <div className="content row">
                                    {featured_work_project.map((work,i) => {
                                        delay.push(delay[i] + 200)
                                        return(
                                            <div 
                                                key={work.id}
                                                className="col-1 project-type wow fadeInLeft" 
                                                data-wow-delay={`${delay}ms`} 
                                                data-wow-duration="1000ms">
                                                {work.title && <Heading {...ProjectTypeHeading}>{work.title}</Heading>}
                                                {work.achivments && (
                                                    <ProjectTypeList>
                                                        {work.achivments.map((achiv, i) => (
                                                            <li key={`${work.id}-${i}`}><Text {...ProjectTypeText}>{achiv}</Text></li>
                                                        ))}
                                                    </ProjectTypeList>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </AboutSectionWrap>
                )}
            </ProjectDetailsWrap>
            <NextProjectWrap>
                <div className="project-container">
                    <div className="col-1 offset-1">
                        <SectionTitle
                            title={next ? "Следующий проект": "Предыдущий проект"}
                            subtitle="Примеры кейсов"
                        />
                    </div>

                    <div className="project-wrapper">
                        {next && (
                            <Project 
                                {...nextProjectStyle}
                                isEven={true}
                                id={next.id} 
                                image={next.featured_image.childImageSharp.fluid}
                                title={next.title}
                                client={next.client}
                                sector={next.sector}
                            />
                        )} 
                        {!next && previous && (
                            <Project 
                                {...nextProjectStyle}
                                isEven={true}
                                id={previous.id} 
                                image={previous.featured_image.childImageSharp.fluid}
                                title={previous.title}
                                client={previous.client}
                                sector={previous.sector}
                            />
                        )}
                    </div>
                </div>
            </NextProjectWrap>
        </Layout> 
    )
}

export const query = graphql `
    query($id: String!){
        projectsJson(id: {eq: $id}){
            id
            title
            titleSEO
            descSEO
            client
            subTitle
            site
            services
            featured_work_project {
                id
                title
                achivments
            }
            sector
            year
            body
            cover_image {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 750, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
            features {
              id
              title
              text
              image {
                childImageSharp {
                  fluid(maxWidth: 1920, maxHeight: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
        }
    }
`;

ProjectTemplate.propTypes = {
    metaTextStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    title: PropTypes.object,
    metaHeading: PropTypes.object,
    metaText: PropTypes.object
}

ProjectTemplate.defaultProps = {
    metaTextStyle: {
        m: 0,
        fontSize: '16px',
        color: '#fff',
        letterspacing: '1px'
    },
    titleStyle: {
        as: 'h1',
        color: '#fff',
        responsive: {
            small:{
                mb: '22px'
            }
        }
    },
    title: {
        color: 'primary',
        fontSize: '40px',
        lineHeight: '55px',
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            }
        }
    },
    metaHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px',
    },
    metaText: {
        m: 0,
        fontSize: '12px',
        color: '#000000',
        letterspacing: '1px',
    },
    nextProjectStyle: {
        mt: '100px',
        responsive: {
            medium: {
                mt: '60px'
            }
        }
    },
    ProjectTypeHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px'
    },
    ProjectTypeText: {
        m: 0,
        fontSize: '12px',
        color: '#000000',
        letterspacing: '1px'
    }
}

export default ProjectTemplate;